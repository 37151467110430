'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

import { SESSION_TOKEN_PLAYTEST_KEY_NAME } from '@/src/app/config/constants'
import { useAuthUser } from '@/src/entities/auth-user/hooks/useAuthUser'
import { ISessionDto } from '@/src/entities/session/api/dto'
import { handleLogin } from '@/src/features/auth/api'
import { apiClientPlaytest } from '@/src/shared/api'
import { setSessionToken } from '@/src/shared/api/storage'

const TelegramAuth = () => {
  const searchParams = useSearchParams()
  const token = searchParams.get('token')
  const router = useRouter()
  const params = new URLSearchParams(window.location.search)
  const { isAuth } = useAuthUser()

  // eslint-disable-next-line
  const getSessionPlaytest = async (): Promise<any> => {
    const res = await apiClientPlaytest.get<ISessionDto>('session')
    return res
  }

  const onHandleLogin = () => {
    getSessionPlaytest().then((response) => {
      const { customer } = response.data || {}
      const user = {
        id: customer.id,
        email: customer.email,
        avatar: customer.avatar_link,
        login: customer.login,
      }

      handleLogin({ ...response, data: { ...response.data, customer: user } })
    })
  }

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        setSessionToken({ token, sessionTokenKeyName: SESSION_TOKEN_PLAYTEST_KEY_NAME })
        onHandleLogin()
      }, 300)
    }
  }, [token])

  useEffect(() => {
    if (token && isAuth) {
      toast.success('Authorized!')
      params.delete('token')
      router.replace(window.location.pathname)
    }
  }, [isAuth, token])

  return <></>
}

export default TelegramAuth
